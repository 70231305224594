import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { useQuery, gql } from '@apollo/client';

class GraphQLService {
  constructor() {
    console.log(process.env.VUE_APP_GRAPHQL_API_URI)
    console.log(process.env.VUE_APP_GRAPHQL_API_KEY)

    this.apolloClient = new ApolloClient({
      uri: process.env.VUE_APP_GRAPHQL_API_URI,
      cache: new InMemoryCache(),
      headers: {
        'x-api-key': process.env.VUE_APP_GRAPHQL_API_KEY || '',
      }
    });
  }

  async query(query, variables = {}) {
    try {
      const { data } = await this.apolloClient.query({ query: gql(query), variables, fetchPolicy: 'no-cache' });
      return data;
    } catch (error) {
      console.error('GraphQL Query Error:', error);
      throw error;
    }
  }

  async mutate(mutation, variables = {}) {
    try {
      const { data } = await this.apolloClient.mutate({ mutation: gql(mutation), variables });
      return data;
    } catch (error) {
      console.error('GraphQL Mutation Error:', error);
      throw error;
    }
  }
}

export default new GraphQLService();
