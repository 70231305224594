import { createApp } from 'vue'
import { CkeditorPlugin } from '@ckeditor/ckeditor5-vue';
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import 'ckeditor5/ckeditor5.css';

loadFonts()

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(CkeditorPlugin)
  .mount('#app')
