<template>
    <v-app-bar app color="primary">
        <v-toolbar-title class="d-none d-sm-flex">{{ appSettings.title || `KEY: title` }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-divider vertical class="mx-2 d-none d-sm-flex"></v-divider>
        
        <!-- Desktop version -->
        <div class="d-none d-sm-flex">
            <v-btn icon to="/"><v-icon>mdi-home</v-icon></v-btn>
            <v-btn icon to="/about"><v-icon>mdi-information</v-icon></v-btn>
            <v-btn icon to="/gallery"><v-icon>mdi-image</v-icon></v-btn>
            <v-btn icon to="/character"><v-icon>mdi-account</v-icon></v-btn>
            <v-btn icon to="/cast"><v-icon>mdi-account-group</v-icon></v-btn>
            <v-btn icon to="/notes"><v-icon>mdi-note-text</v-icon></v-btn>
            <v-btn icon to="/lore"><v-icon>mdi-magnify</v-icon></v-btn>
            <v-btn icon to="/world"><v-icon>mdi-earth</v-icon></v-btn>
            <v-btn icon to="/story"><v-icon>mdi-book-open-variant</v-icon></v-btn>
            <v-btn icon to="/quests"><v-icon>mdi-compass</v-icon></v-btn>
            <v-btn icon to="/settings" v-if="appMode === 'development'"><v-icon>mdi-cog</v-icon></v-btn>
        </div>

        <!-- Mobile version -->
        <v-app-bar-nav-icon class="d-sm-none" @click="drawer = !drawer"></v-app-bar-nav-icon>
    </v-app-bar>

    <!-- Mobile navigation drawer -->
    <v-navigation-drawer v-model="drawer" temporary app class="d-sm-none">
        <v-list>
            <v-list-item to="/" link>
                <template v-slot:prepend>
                    <v-icon>mdi-home</v-icon>
                </template>
                <v-list-item-title>Home</v-list-item-title>
            </v-list-item>
            <v-list-item to="/about" link>
                <template v-slot:prepend>
                    <v-icon>mdi-information</v-icon>
                </template>
                <v-list-item-title>About</v-list-item-title>
            </v-list-item>
            <v-list-item to="/gallery" link>
                <template v-slot:prepend>
                    <v-icon>mdi-image</v-icon>
                </template>
                <v-list-item-title>Gallery</v-list-item-title>
            </v-list-item>
            <v-list-item to="/character" link>
                <template v-slot:prepend>
                    <v-icon>mdi-account</v-icon>
                </template>
                <v-list-item-title>Character</v-list-item-title>
            </v-list-item>
            <v-list-item to="/cast" link>
                <template v-slot:prepend>
                    <v-icon>mdi-account-group</v-icon>
                </template>
                <v-list-item-title>Cast</v-list-item-title>
            </v-list-item>
            <v-list-item to="/notes" link>
                <template v-slot:prepend>
                    <v-icon>mdi-note-text</v-icon>
                </template>
                <v-list-item-title>Notes</v-list-item-title>
            </v-list-item>
            <v-list-item to="/lore" link>
                <template v-slot:prepend>
                    <v-icon>mdi-magnify</v-icon>
                </template>
                <v-list-item-title>Lore</v-list-item-title>
            </v-list-item>
            <v-list-item to="/world" link>
                <template v-slot:prepend>
                    <v-icon>mdi-earth</v-icon>
                </template>
                <v-list-item-title>World</v-list-item-title>
            </v-list-item>
            <v-list-item to="/story" link>
                <template v-slot:prepend>
                    <v-icon>mdi-book-open-variant</v-icon>
                </template>
                <v-list-item-title>Story</v-list-item-title>
            </v-list-item>
            <v-list-item to="/quests" link>
                <template v-slot:prepend>
                    <v-icon>mdi-compass</v-icon>
                </template>
                <v-list-item-title>Quests</v-list-item-title>
            </v-list-item>
            <v-list-item to="/settings" link v-if="appMode === 'development'">
                <template v-slot:prepend>
                    <v-icon>mdi-cog</v-icon>
                </template>
                <v-list-item-title>Settings</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
export default {
    name: 'AppBarPanel',
    computed: {
        appMode() {
            return process.env.VUE_APP_MODE
        },
        appSettings() {
            return this.$store.getters.AppSettings
        }
    },
    data() {
        return {
            drawer: false
        }
    }
}
</script>