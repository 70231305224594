<template>
    <v-container fluid>
        <AppBarPanel />
        <v-row>
            <v-col cols="12">
                <slot />
            </v-col>
        </v-row>
        <v-footer app>
            <v-col class="text-right">
                <div class="d-flex justify-end">
                    <v-btn variant="text" to="/privacy-policy">Privacy Policy</v-btn>
                </div>

                <div class="d-flex justify-end">
                    <v-btn variant="text" to="/terms-and-conditions">Terms and Conditions</v-btn>
                </div>
            </v-col>
        </v-footer>
    </v-container>
</template>

<script>
import AppBarPanel from '@/components/AppBarPanel.vue'

export default {
    name: 'DefaultTemplate',
    components: {
        AppBarPanel
    }
}
</script>

<style scoped>
</style>