import Vue from 'vue'
import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  { 
    path: '/', 
    name: 'home', 
    component: () => import('@/views/HomeView.vue'),
    meta: { title: 'Home' }
  },
  { 
    path: '/about', 
    name: 'about', 
    component: () => import('@/views/AboutView.vue'),
    meta: { title: 'About' }
  },
  { 
    path: '/character', 
    name: 'character', 
    component: () => import('@/views/CharacterView.vue'),
    meta: { title: 'Character' }
  },
  { 
    path: '/lore', 
    name: 'lore', 
    component: () => import('@/views/LoreView.vue'),
    meta: { title: 'Lore' }
  },
  { 
    path: '/notes', 
    name: 'notes', 
    component: () => import('@/views/NotesView.vue'),
    meta: { title: 'Notes' }
  },
  { 
    path: '/world', 
    name: 'world', 
    component: () => import('@/views/WorldView.vue'),
    meta: { title: 'World' }
  },
  { 
    path: '/cast', 
    name: 'cast', 
    component: () => import('@/views/CastView.vue'),
    meta: { title: 'Cast' }
  },
  { 
    path: '/story', 
    name: 'story', 
    component: () => import('@/views/StoryView.vue'),
    meta: { title: 'Story' }
  },
  { 
    path: '/gallery', 
    name: 'gallery', 
    component: () => import('@/views/GalleryView.vue'),
    meta: { title: 'Gallery' }
  },
  { 
    path: '/quests', 
    name: 'quests', 
    component: () => import('@/views/QuestView.vue'),
    meta: { title: 'Quests' }
  },
  { 
    path: '/settings', 
    name: 'settings', 
    component: () => import('@/views/SettingsView.vue'),
    meta: { title: 'Settings' }
  },
  { 
    path: '/terms-and-conditions', 
    name: 'terms-and-conditions', 
    component: () => import('@/views/TermsAndConditions.vue'),
    meta: { title: 'Terms and Conditions' }
  },
  { 
    path: '/privacy-policy', 
    name: 'privacy-policy', 
    component: () => import('@/views/PrivacyPolicyView.vue'),
    meta: { title: 'Privacy Policy' }
  },
]

const router = createRouter({
  mode: 'history',
  history: createWebHistory(),
  routes
})

router.afterEach((to) => {
  document.title = `A God's Fate | ${to.meta.title}` || 'A God\'s Fate'
})

export default router
