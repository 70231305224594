import { createStore } from 'vuex'
import { JsonData } from '@/data/index.js';
import GraphQLService from '@/plugins/graphql.js';
import { listCasts, listCharacters, listLorePoints, listNotes, listQuests, listStoryPoints, listWorldPoints, listComicPanels, listAppSettings } from '@/graphql/queries.js';

export default createStore({
  state: {
    appSettings: undefined,
    cast: undefined,
    characters: undefined,
    lore: undefined,
    notes: undefined,
    quests: undefined,
    story: undefined,
    world: undefined,
    comics: undefined,
    snackbar: {
      show: false,
      color: 'success',
      timeout: 5000,
      multiLine: false,
      vertical: false,
      text: 'This is a snackbar message'
    }
  },
  mutations: {
    setAppSettings(state, appSettings) {
      state.appSettings = appSettings
    },
    setCast(state, cast) {
      state.cast = cast
    },
    setCharacters(state, characters) {
      state.characters = characters
    },
    setLore(state, lore) {
      state.lore = lore
    },
    setNotes(state, notes) {
      state.notes = notes
    },
    setQuests(state, quests) {
      state.quests = quests
    },
    setStory(state, story) {
      state.story = story
    },
    setWorld(state, world) {
      state.world = world
    },
    setSnackbar(state, snackbar) {
      state.snackbar = snackbar
    },
    setComics(state, comics) {
      state.comics = comics
    }
  },
  actions: {
    refreshData({commit}) {
      GraphQLService.query(listAppSettings).then(data => commit('setAppSettings', data.listAppSettings.items) ).catch(error => console.error('Error fetching app settings:', error) )
      GraphQLService.query(listComicPanels).then(data => commit('setComics', data.listComicPanels.items) ).catch(error => console.error('Error fetching comics:', error) )
      GraphQLService.query(listCasts).then(data => commit('setCast', data.listCasts.items.map( (item) => { return { ...item, relationships: JSON.parse(item.relationships)}} )) ).catch(error => console.error('Error fetching cast:', error) )
      GraphQLService.query(listCharacters).then(data => commit('setCharacters', data.listCharacters.items.map( (item) => { return { ...item, stats: JSON.parse(item.stats), relationships: JSON.parse(item.relationships), inventory: JSON.parse(item.inventory), metadata: JSON.parse(item.metadata) }})) ).catch(error => console.error('Error fetching characters:', error) )
      GraphQLService.query(listLorePoints).then(data => commit('setLore', data.listLorePoints.items) ).catch(error => console.error('Error fetching lore:', error) )
      GraphQLService.query(listNotes).then(data => commit('setNotes', data.listNotes.items) ).catch(error => console.error('Error fetching notes:', error) )
      GraphQLService.query(listQuests).then(data => commit('setQuests', data.listQuests.items) ).catch(error => console.error('Error fetching quests:', error) )
      GraphQLService.query(listStoryPoints).then(data => commit('setStory', data.listStoryPoints.items) ).catch(error => console.error('Error fetching story:', error) )
      GraphQLService.query(listWorldPoints).then(data => commit('setWorld', data.listWorldPoints.items.map( (item) => { return { ...item, coordinates: JSON.parse(item.coordinates)}} )) ).catch(error => console.error('Error fetching world:', error) )
      console.log('Data refreshed')
    },
    updateData({commit}, payload) {
      switch (payload.key) {
        case 'cast':
          commit('setCast', payload.data)
          break
        case 'characters':
          commit('setCharacters', payload.data)
          break
        case 'lore':
          commit('setLore', payload.data)
          break
        case 'notes':
          commit('setNotes', payload.data)
          break
        case 'quests':
          commit('setQuests', payload.data)
          break
        case 'story':
          commit('setStory', payload.data)
          break
        case 'world':
          commit('setWorld', payload.data)
          break
        case 'appSettings':
          commit('setAppSettings', payload.data)
          break
        default:
          console.error('Invalid key:', payload.key)
      }
    },
    updateSnackbar({commit}, payload) {
      commit('setSnackbar', payload)
    }
  },
  getters: {
    AppSettings: state => {
      const settings = {}
      for (const setting of state.appSettings) {
        settings[setting.key] = setting.value
      }
      return settings
    },
    Loaded: state => {
      return state.appSettings && state.comics && state.cast && state.characters && state.lore && state.notes && state.quests && state.story && state.world
    }
  },
  modules: {
  }
})
