<template>
  <v-app>
    <v-main v-if="loaded">
      <DefaultTemplate>
        <router-view />
      </DefaultTemplate>
    </v-main>
    <v-main v-else class="d-flex justify-center align-center">
      <v-progress-circular indeterminate class="mr-2" />
      Loading...
    </v-main>
  </v-app>
</template>


<script>
import DefaultTemplate from '@/components/DefaultTemplate.vue'

export default {
  name: 'App',
  components: {
    DefaultTemplate
  },
  computed: {
    appSettings() {
      return this.$store.getters.AppSettings
    },
    loaded() {
      return this.$store.getters.Loaded
    }
  },
  mounted() {
    console.log('mounted app')
    this.$store.dispatch('refreshData')
  },
  created() {
    console.log('created app')
  }
}
</script>

<style>
</style>
